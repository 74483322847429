import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
  return (
    <Layout>
      <SEO title="TOS" />
      <p>
        Protecting your private information is our priority at Millionaire Millennial. This Privacy Policy informs you of our policies
        regarding the collection, use and disclosure of personal data Users of
        the Web site and/or Services. Millionaire Millennial attaches great
        importance to ensuring that your personal data and crawled data are
        collected, used, processed and erased in accordance with the current
        applicable regulations as stipulated by local, state, national, federal,
        and other data protection legislation. Protecting your data is a matter
        of great importance to our company. If you have requests concerning your
        personal information or any questions please contact us
        support@millionairemillennial.me.
      </p>
      <p>
        If you have objections to the Privacy Policy, you should immediately
        discontinue use of the Web site and our Services.
      </p>
      <p>&nbsp;</p>
      <p>Definitions</p>
      <p>&nbsp;</p>
      <p>
        For purposes of this Privacy Policy references to &ldquo;Customer&rdquo;
        mean a registered, paying User of the Services.
      </p>
      <p>&nbsp;</p>
      <p>
        For purposes of this Privacy Policy references to &ldquo;us,&rdquo;
        &ldquo;we,&rdquo; &ldquo;our,&rdquo; and/or &ldquo;Millionaire Millennial&rdquo; mean Millionaire Millennial, with a registered place of business located at
        10601 Clarence Drive #250 Frisco, TX, and its subsidiaries, parents, and
        affiliates.
      </p>
      <p>
        References to the &ldquo;Services&rdquo; mean those consulting services
        provided by us.
      </p>
      <p>&nbsp;</p>
      <p>
        References to the &ldquo;Web site&rdquo; mean the Web sites bearing the
        URL www.millionairemillennial.me, as well as any other site owned and/or
        operated by or under the Millionaire Millennial brand.
      </p>
      <p>
        References to &ldquo;you,&rdquo; and/or &ldquo;User,&rdquo; mean the
        User of the Web site and/or the Services.
      </p>
      <p>&nbsp;</p>
      <p>Agreement to be Bound</p>
      <p>&nbsp;</p>
      <p>
        The following Data Protection and Privacy Policy Statement governs the
        collection and use of private information via the Web site and the
        Services. By accessing the Web site and/or registering for the use of
        our Services you represent that you have read and understand the Privacy
        Policy and that you agree to be bound by thereby.
      </p>
      <p>&nbsp;</p>
      <p>
        This Privacy Policy may be additionally subject to our Terms of Service
        for those Customers who use our Services.
      </p>
      <p>&nbsp;</p>
      <p>Modifications and Changes to the Privacy Policy</p>
      <p>&nbsp;</p>
      <p>
        We may modify, add to, suspend, or delete the Privacy Policy, in whole
        or in part, at our sole discretion at any time, with such modifications,
        additions or deletions being effective thirty (30) calendar days
        following their posting to the Web and emailing a notice to registered
        Users. Your access of the Web site and/or use of our Services, after
        modification, addition or deletion of the Privacy Policy Statement shall
        be deemed to constitute acceptance by you of the modification, addition
        or deletion.
      </p>
      <p>&nbsp;</p>
      <p>Collection of your Personal Information</p>
      <p>&nbsp;</p>
      <p>
        We collect both personally-identifiable information or personal data and
        non-personally-identifiable information from you. Often, you choose what
        information to provide to us, but, sometimes, we may require certain
        information for you to use and for us to provide the Services.
        Specifically, we collect the following:
      </p>
      <p>&nbsp;</p>
      <p>
        Registration &amp; Account Set Up collected via the Web site. In order
        to register you as a Customer, provide you with support, process your
        transactions, or allow you to participate in a contest, we will collect
        personal data, which may include your full name; telephone number; and
        email address. We also will collect a user name and password.
      </p>
      <p>&nbsp;</p>
      <p>
        In addition, you should note that our payment processors may collect and
        store sensitive financial information, including your credit card
        number, expiration date, and billing details for those Customers who pay
        us via credit card. We store the same only as anonymous data and have no
        access to or control over the sensitive financial information you may
        provide.
      </p>
      <p>&nbsp;</p>
      <p>
        Automated Information. Millionaire Millennial automatically receives
        and records information from each User&rsquo;s browser or your mobile
        device when you visit the Web site or use certain other Services, such
        as your IP address in an anonymized fashion or unique device identifier,
        cookies and data about which pages you visit in order to allow us to
        operate and provide the Services, and data about the type of device you
        use to connect to our Services and/or Web site. This information is
        stored in log files and is collected automatically. We may combine this
        information from your browser or your mobile device with other
        information that we or our partners collect about you, including across
        devices. This information is used to prevent fraud and to keep the
        Services secure, to analyze and understand how the Services work for our
        Users, and to provide advertising, including across your devices, and a
        more personalized experience for members and visitors.
      </p>
      <p>&nbsp;</p>
      <p>
        We may also automatically collect device-specific information when you
        install, access, or use our Services. This information may include
        information such as the hardware model, operating system information,
        app version, app usage and debugging information, browser information,
        IP address, and device identifiers.
      </p>
      <p>&nbsp;</p>
      <p>
        Location Information: We may collect information about your use of the
        Services for advertising, analytics, to serve content and to protect the
        Services, including your IP address, browser information (including
        referrers), device information (such as iOS IDFA, IDFV for limited
        non-advertising purposes, Android AAID, and, when enabled by you,
        location information provided by your device). We may obtain location
        information you provide in your profile or your IP address. With your
        consent, we may also determine location by using other information from
        your device, such as general location information from GPS or
        information about wireless networks or cell towers near your mobile
        device. We may use and store information about your location to provide
        features and to improve and customize the Services, for example, for our
        internal analytics and performance monitoring; localization, regional
        requirements, and policies for the Services; for local content, search
        results, and recommendations; for mapping services; and (using
        non-precise location information) marketing. If you have consented to
        share your precise device location details but would no longer like to
        continue sharing that information with us, you may revoke your consent
        to the sharing of that information through the settings on your mobile
        device. Certain non-precise location services, such as for security and
        localized policies based on your IP address or submitted address, are
        critical for the site to function. We will only share your geo-location
        details with third parties (like our mapping, payments, or, to the
        extent applicable, advertising providers) in order to provide you with
        the Services.
      </p>
      <p>&nbsp;</p>
      <p>
        Analytics Information: We use data analytics to ensure site
        functionality and improve the Services. We do not link the information
        we store within the analytics software to any personally identifiable
        information that you submit.
      </p>
      <p>&nbsp;</p>
      <p>Use of your Personal Information</p>
      <p>&nbsp;</p>
      <p>
        We may use your personally-identifiable information to process
        transactions; to provide and improve customer service administration; to
        personalize your User experience; to contact Users via e-mail, SMS text,
        or telephone; to respond to inquiries, and/or other requests or
        questions; to send company news, updates and other related info via our
        mailing list; and to facilitate registration for and use of our Services
        .
      </p>
      <p>&nbsp;</p>
      <p>
        We may use your non-personally identifiable information to create
        traffic statistics for our Web site; to create User statistics for our
        Services; to improve customer service; and to personalize your User
        experience.
      </p>
      <p>&nbsp;</p>
      <p>
        Millionaire Millennial gives you the choice of providing, editing or
        removing certain information, as well as choices about how we contact
        you. You may change or correct your Millionaire Millennial account
        information through your account settings. You can also request the
        deletion of the personal information in your account.
      </p>
      <p>&nbsp;</p>
      <p>
        Depending on your location, you may also have certain additional rights
        with respect to your information, such as: (i) data access and
        portability (including the right to obtain a copy of your personal data
        you provided to Millionaire Millennial, via your settings); (ii)
        data correction (including the ability to update your personal data, in
        many cases via settings); (iii) data deletion (including the right to
        have Millionaire Millennial delete your personal information, except
        information we are required to retain, by contacting us); and (iv)
        withdrawal of consent or objection to processing (including, in limited
        circumstances, the right to ask Millionaire Millennial to stop
        processing your personal data, with some exceptions, by contacting us).
      </p>
      <p>&nbsp;</p>
      <p>
        You may also control the receipt of certain types of communications from
        Millionaire Millennial. Millionaire Millennial may send you
        messages about the Services or your activity. Some of these messages are
        required, service-related messages for Users (such as transactional
        messages or legal notices). Other messages are not required, such as
        newsletters. You can control which optional messages you choose to
        receive via the unsubscribe link in our emails.
      </p>
      <p>&nbsp;</p>
      <p>
        If you no longer wish to use the Services or receive service-related
        messages (except for legally required notices), then you may close your
        account.
      </p>
      <p>&nbsp;</p>
      <p>Sharing Information with Third Parties</p>
      <p>&nbsp;</p>
      <p>
        Millionaire Millennial does not sell, rent or lease its customer
        lists to third parties.
      </p>
      <p>
        Millionaire Millennial may share data with trusted partners to help
        perform statistical analysis, send you email or postal mail, provide
        customer support, or provided marketing services. For example, we share
        data with ClickFunnels, our Web site administrator, and Google Analytics
        and Google Remarketing, whose services we utilize for marketing
        purposes. All such third parties are prohibited from using your personal
        information except to provide these services to Millionaire Millennial
        , and they are required to maintain the confidentiality of your
        information.
      </p>
      <p>&nbsp;</p>
      <p>
        Millionaire Millennial may disclose your personal information,
        without notice, if required to do so by law or in the good faith belief
        that such action is necessary to: (a) conform to the edicts of the law
        or comply with legal process served on Millionaire Millennial or the
        site; (b) protect and defend the rights or property of Millionaire Millennial; and/or (c) act under exigent circumstances to protect
        the personal safety of users of Millionaire Millennial, or the
        public. Pursuant to the California Consumer Privacy Act (CCPA),
        Millionaire Millennial has not sold or disclosed consumers&rsquo;
        personal information to a third party in the preceding 12 months.
      </p>
      <p>&nbsp;</p>
      <p>Automatically Collected Information</p>
      <p>&nbsp;</p>
      <p>
        Information about your computer hardware and software may be
        automatically collected by Millionaire Millennial. This information
        can include: your IP address, browser type, domain names, access times
        and referring website addresses. This information is used for the
        operation of the service, to maintain quality of the service, and to
        provide general statistics regarding use of the Millionaire Millennial
        website.
      </p>
      <p>&nbsp;</p>
      <p>Use of Cookies</p>
      <p>&nbsp;</p>
      <p>
        The Millionaire Millennial website may use &ldquo;cookies&rdquo; to
        help you personalize your online experience. A cookie is a text file
        that is placed on your hard disk by a web page server. Cookies cannot be
        used to run programs or deliver viruses to your computer. Cookies are
        uniquely assigned to you, and can only be read by a web server in the
        domain that issued the cookie to you.
      </p>
      <p>&nbsp;</p>
      <p>
        One of the primary purposes of cookies is to provide a convenience
        feature to save you time. The purpose of a cookie is to tell the Web
        server that you have returned to a specific page. For example, if you
        personalize Millionaire Millennial pages, or register with
        Millionaire Millennial site or services, a cookie helps Millionaire Millennial to recall your specific information on subsequent visits.
        This simplifies the process of recording your personal information, such
        as billing addresses, shipping addresses, and so on. When you return to
        the same Millionaire Millennial website, the information you
        previously provided can be retrieved, so you can easily use the
        Millionaire Millennial features that you customized.
      </p>
      <p>&nbsp;</p>
      <p>
        You have the ability to accept or decline cookies. Most Web browsers
        automatically accept cookies, but you can usually modify your browser
        setting to decline cookies if you prefer. If you choose to decline
        cookies, you may not be able to fully experience the interactive
        features of the Millionaire Millennial services or websites you
        visit.
      </p>
      <p>&nbsp;</p>
      <p>Security of your Personal Information</p>
      <p>&nbsp;</p>
      <p>
        Millionaire Millennial secures your personal information from
        unauthorized access, use, or disclosure. Millionaire Millennial uses
        the following methods for this purpose:
      </p>
      <ul>
        <li>SSL Protocol</li>
        <li>Vulnerability scanning</li>
        <li>PCI-Scanningf</li>
        <li>S-HTTP</li>
      </ul>
      <p>&nbsp;</p>
      <p>
        When personal information (such as a credit card number) is transmitted
        to other websites, it is protected through the use of encryption, such
        as the Secure Sockets Layer (SSL) protocol.
      </p>
      <p>
        In addition, access to your data is restricted to need-to-access
        individuals.
      </p>
      <p>&nbsp;</p>
      <p>
        We strive to take appropriate security measures to protect against
        unauthorized access to or alteration of your personal information.
        Unfortunately, no data transmission over the Internet or any wireless
        network can be guaranteed to be 100% secure. As a result, while we
        strive to protect your personal information, you acknowledge that: (a)
        there are security and privacy limitations inherent to the Internet
        which are beyond our control; and (b) security, integrity, and privacy
        of any and all information and data exchanged between you and us through
        this Site cannot be guaranteed. Please note that non-encrypted
        communication using email is not secure. Thus, we cannot guarantee data
        security in email communication and, therefore, recommend using physical
        mail for confidential information.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>TERMS OF PARTICIPATION</p>
      <p>
        Please READ Carefully by purchasing this product you (herein referred to
        as &ldquo;Client&rdquo;) agrees to the follow terms stated herein.
      </p>
      <p>&nbsp;</p>
      <p>PROGRAM/SERVICE</p>
      <p>
        Millionaire Millennial (herein referred to as &ldquo;Millionaire Millennial&rdquo; or &ldquo;Company&rdquo;) agrees to provide
        Educational Training (herein referred to as &ldquo;Program) identified
        in online commerce shopping cart. Client agrees to abide by all policies
        and procedures as outlined in this agreement as a condition of their
        participation in the Program.
      </p>
      <p>&nbsp;</p>
      <p>DISCLAIMER</p>
      <p>
        Client understands Millionaire Millennial (herein referred to as
        &ldquo;Consultant&rdquo;) is not an employee, agent, lawyer, doctor,
        manager, therapist, public relations or business manager, registered
        dietician, or financial analyst, psychotherapist or accountant. Client
        understands that Consultant has not promised, shall not be obligated to
        and will not; (1) procure or attempt to procure employment or business
        or sales for Client; (2) Perform any business management functions
        including but not limited to, accounting, tax or investment consulting,
        or advice with regard thereto; (3) act as a therapist providing
        psychoanalysis, psychological counseling or behavioral therapy; (4) act
        as a public relations manager (5) act as a publicist to procure any
        publicity, interviews, write-ups, features, television, print or digital
        media exposure for Client; (6) introduce Client to Consultant&rsquo;s
        full network of contacts, media partners or business partners. Client
        understands that a relationship does not exist between the parties after
        the conclusion of this program. If the Parties continue their
        relationship, a separate agreement will be entered into.
      </p>
      <p>&nbsp;</p>
      <p>METHODS OF PAYMENT</p>
      <p>
        If Client elects to pay by monthly installments, Client authorizes the
        Company to charge Client&rsquo;s credit card or debit card. If Client
        elects to pay in FULL, Client may pay by credit card or debit card or
        PayPal.
      </p>
      <p>&nbsp;</p>
      <p>REFUND POLICY</p>
      <p>
        We want you to be satisfied with your purchase but we also want you to
        give your best effort to apply all of the strategies in the course. We
        offer a 30-day refund period for purchases if less than 40% of total
        course material has been viewed (we use a database provider who provides
        accurate metrics regarding viewership of total course content). However,
        in order to qualify for a refund, you must submit proof that you did the
        work in the course and it did not work for you. Refund requests within
        24 hours of purchase may require additional verification in an effort to
        prevent piracy.
      </p>
      <p>&nbsp;</p>
      <p>
        In the event that you decide your purchase was not the right decision,
        within 30 days of enrollment, contact our support team at
        support@millionairemillennial.me and let us know you&rsquo;d like a
        refund by the 30th day. You must include your coursework with your
        request for a refund. If you request a refund and do not include your
        coursework by the 30th day, you will not be granted a refund. All refund
        requests require submission of the respective refund request form.
      </p>
      <p>&nbsp;</p>
      <p>
        Refunds are given only when requested by email, by a student who has
        purchased the course less than 30 days prior, and has completed less
        than 40% of the course. (ALL DISCOUNTED PURCHASES ARE NON-REFUNDABLE)
        Absolutely NO exceptions.
      </p>
      <p>&nbsp;</p>
      <p>
        The work that you need to submit with your request for a refund includes
        ALL of the following items:
      </p>
      <p>&nbsp;</p>
      <p>Action Based Refund Requirements:</p>
      <p>&nbsp;</p>
      <p>Worksheet from Week 1.</p>
      <p>&nbsp;</p>
      <p>
        We will NOT provide refunds more than 30 days following the date of
        purchase. After day 30, all payments are non-refundable and you are
        responsible for full payment of the fees for the program regardless if
        you complete the program.
      </p>
      <p>&nbsp;</p>
      <p>
        Please note: If you opted for a payment plan and you do not request a
        refund within 30 days, with the required coursework at the time of your
        refund request, you are required by law to complete the remaining
        payments of your payment plan.
      </p>
      <p>&nbsp;</p>
      <p>
        All refunds are discretionary as determined by Millionaire Millennial. To further clarify, we will not provide refunds after the 30th day
        from your date of purchase and all payments must be made on a timely
        basis. If payments are not made on time, you agree to pay interest on
        all past-due sums at a rate of 1.5% per month or the highest rate
        allowed by law, whichever is greater.
      </p>
      <p>&nbsp;</p>
      <p>
        If you have any questions or problems, please let us know by contacting
        our support team directly. The support desk can be reached at:
        support@millionairemillennial.me
      </p>
      <p>&nbsp;</p>
      <p>CONFIDENTIALITY</p>
      <p>
        The Company respects Client&rsquo;s privacy and insists that Client
        respects the Company&rsquo;s and Program Participants (herein referred
        to as &ldquo;Participants&rdquo;). Thus, consider this a mutual
        non-disclosure agreement. Any Confidential Information shared by Program
        participants or any representative of the Company is confidential,
        Proprietary, and belongs solely and exclusively to the Participant who
        discloses it. Parties agree not to disclose, reveal or make use of any
        Confidential Information or any transactions, during discussions, on the
        forum or otherwise. Client agrees not to use such confidential
        information in any manner other than in discussion with other
        Participants during Program. Participants agree to be contacted through
        the methods and details provided at the time of registration on matters
        regarding, but not limited to: program details, new offers, balances due
        and collections; unless otherwise requested by the consumer. By
        providing your phone number you affirmatively consent to Millionaire Millennial, and its participating partners and third parties, to
        send you telemarking messages via sms or mms your listed phone number
        using an autodialer. Confidential Information includes, but is not
        limited to, information disclosed in connection with this Agreement, and
        shall not include information rightfully obtained from a third party.
        Both Parties will keep Confidential Information in strictest confidence
        and shall use the best efforts to safeguard the Confidential Information
        and to protect it against disclosure, misuse, espionage, loss and theft.
        Client agrees not to violate the Company&rsquo;s publicity or privacy
        rights. Furthermore Client will NOT reveal any information to a third
        party obtained in connection with this Agreement or Company&rsquo;s
        direct or indirect dealings with Client including but not limited to;
        names, email addresses, third-party company titles or positions, phone
        numbers or addresses. Additionally, Consultant will not, at any time,
        either directly or indirectly, disclose confidential information to any
        third party. Further, by purchasing this product you agree that if you
        violate or display any likelihood of violating this session the Company
        and/or the other Program participant(s) will be entitled to injunctive
        relief to prohibit any such violations to protect against the harm of
        such violations.
      </p>
      <p>&nbsp;</p>
      <p>NO TRANSFER OF INTELLECTUAL PROPERTY</p>
      <p>
        Millionaire Millennial&rsquo;s program is copyrighted and
        original materials that have been provided to Client are for
        Client&rsquo;s individual use only and a single-user license. The Client
        is not authorized to use any of Company&rsquo;s intellectual property
        for Client&rsquo;s business purposes. All intellectual property,
        including Company&rsquo;s copyrighted program and/or course materials,
        shall remain the sole property of the Millionaire Millennial. No
        license to sell or distribute Company&rsquo;s materials is granted or
        implied. By purchasing this product, Client agrees (1) not to infringe
        any copyright, patent, trademark, trade secret, or other intellectual
        property rights, (2) that any Confidential Information shared by the
        Company is confidential and proprietary, and belongs solely and
        exclusively to the Company, (3) Client agrees not to disclose such
        information to any other person or use it in any manner other than in
        discussion with the Company. Further, by purchasing this product, Client
        agrees that if Client violates, or displays any likelihood of violating,
        any of Client&rsquo;s agreements contained in this paragraph, the
        Company will be entitled to injunctive relief to prohibit any such
        violations and to protect against the harm of such violations.
      </p>
      <p>&nbsp;</p>
      <p>CLIENT RESPONSIBILITY</p>
      <p>
        The program is developed for strictly educational purposes ONLY. Client
        accepts and agrees that Client is 100% responsible for their progress
        and results from the Program. The Company makes no representations,
        warranties or guarantees verbally or in writing. Client understands that
        because of the nature of the program and extent, the results experienced
        by each client may significantly vary. Client acknowledges that as with
        any business endeavor, there is an inherent risk of loss of capital and
        there is no guarantee that Client will reach their goals as a result of
        participation in the Program. Program education and information is
        intended for a general audience and does not purport to be, nor should
        it be construed as, specific advice tailored to any individual. The
        company assumes no responsibility for errors or omissions that may
        appear in any program materials.
      </p>
      <p>&nbsp;</p>
      <p>LIMITED ACCESS</p>
      <p>
        Lifetime access is defined as the lifetime of the product, meaning that
        provided you have met all payment obligations and abided by the terms of
        service, you will continue to have access to the course until it is
        discontinued, the website is no longer in service, or the company
        closes, shuts down or files for bankruptcy; Whichever occurs first. A
        decision to discontinue will be made at the sole discretion of
        Millionaire Millennial.
      </p>
      <p>
        The course is provided as is. Updates or changes may be made available
        to existing accounts but are not guaranteed.
      </p>
      <p>
        While we make every effort to provide consistent uninterrupted service,
        we do not guarantee a specific availability. Service interruptions may
        occur from time to time due to vendor updates, outages or service
        issues. When found we will work with our staff and vendors to restore
        access as quickly as possible, but make no warranties as to time, speed,
        or availability.
      </p>
      <p>&nbsp;</p>
      <p>INDEPENDENT CONTRACTOR STATUS</p>
      <p>
        Nothing in this Agreement is to be construed as creating a partnership,
        venture alliance, or any other similar relationship. Each party shall be
        an independent contractor in its performance hereunder and shall retain
        control over its personnel and the manner in which such personnel
        performs hereunder. In no event shall such persons be deemed employees
        of the other party by virtue of participation or performance hereunder.
      </p>
      <p>&nbsp;</p>
      <p>FORCE MAJEURE</p>
      <p>
        In the event that any cause beyond the reasonable control of either
        Party, including without limitation acts of God, war, curtailment or
        interruption of transportation facilities, threats or acts of terrorism,
        State Department travel advisory, labor strike or civil disturbance,
        make it inadvisable, illegal, or impossible, either because of
        unreasonable increased costs or risk of injury, for either Company to
        perform its obligations under this Agreement, the Company&rsquo;s
        performance shall be extended without liability for the period of delay
        or inability to perform due to such occurrence.
      </p>
      <p>&nbsp;</p>
      <p>SEVERABILITY/WAIVER</p>
      <p>
        If any provision of this Agreement is held by to be invalid or
        unenforceable, the remaining provisions shall nevertheless continue in
        full force. The failure of either Party to exercise any right provided
        for herein will not be deemed a waiver of that right or any further
        rights hereunder.
      </p>
      <p>&nbsp;</p>
      <p>LIMITATION OF LIABILITY</p>
      <p>
        Client agrees they used Company&rsquo;s services at their own risk and
        that Program is only an educational service being provided. Client
        releases Company, its officers, employees, directors, subsidiaries,
        principals, agents, heirs, executors, administrators, successors,
        assigns, Instructors, guides, staff, Participants, and related entities
        any way as well as the venue where the Programs are being held (if
        applicable) and any of its owners, executives, agents, or staff
        (hereinafter &ldquo;Releasees&rdquo;) from any and all damages that may
        result from any claims arising from any agreements, all actions, causes
        of action, contracts, claims, suits, costs, demands and damages of
        whatever nature or kind in law or in equity arising from my
        participation in the Programs. Client accepts any and all risks,
        foreseeable or unforeseeable. Client agrees that Company will not be
        held liable for any damages of any kind resulting or arising from
        including but not limited to; direct, indirect, incidental, special,
        negligent, consequential, or exemplary damages happening from the use or
        misuse of Company&rsquo;s services or enrolment in the Program. The
        company assumes no responsibility for errors or omissions that may
        appear in any of the program materials. You also understand that any
        testimonials or endorsements by our customers or audience represented on
        our programs, websites, content, landing pages, sales pages or offerings
        have not been scientifically evaluated by us and the results experienced
        by individuals may vary significantly.
      </p>
      <p>&nbsp;</p>
      <p>NON-DISPARAGEMENT</p>
      <p>
        The Parties agree and accept that the only venue for resolving such a
        dispute shall be in the venue set forth herein below. The parties agree
        that they neither will engage in any conduct or communications with a
        third party, public or private, designed to disparage the other. Neither
        Client nor any of Client&rsquo;s associates, employees or affiliates
        will directly or indirectly, in any capacity or manner, make, express,
        transmit speak, write, verbalize or otherwise communicate in any way (or
        cause, further, assist, solicit, encourage, support or participate in
        any of the foregoing), any remark, comment, message, information,
        declaration, communication or other statement of any kind, whether
        verbal, in writing, electronically transferred or otherwise, that might
        reasonably be construed to be derogatory or critical of, or negative
        toward, the Company or any of its programs, members, owner directors,
        officers, Affiliates, subsidiaries, employees, agents or
        representatives.
      </p>
      <p>&nbsp;</p>
      <p>ASSIGNMENT</p>
      <p>
        The Client may not assign this Agreement without the express written
        consent of Company.
      </p>
      <p>&nbsp;</p>
      <p>MODIFICATION</p>
      <p>
        The Company may modify terms of this agreement at any time. All
        modifications shall be posted on the official website and purchasers
        shall be notified.
      </p>
      <p>&nbsp;</p>
      <p>TERMINATION</p>
      <p>
        The Company is committed to providing all clients in the Program with a
        positive Program experience. By purchasing this product, Client agrees
        that the Company may, at its sole discretion, terminate this Agreement,
        and limit, suspend, or terminate Client&rsquo;s participation in the
        Program without refund or forgiveness of monthly payments if Client
        becomes disruptive to Company or Participants, Client fails to follow
        the Program guidelines, is difficult to work with, impairs the
        participation of the other participants in the Program or upon violation
        of the terms as determined by Company. Client will still be liable to
        pay the total contract amount.
      </p>
      <p>&nbsp;</p>
      <p>INDEMNIFICATION</p>
      <p>
        Client shall defend, indemnify, and hold harmless Company,
        Company&rsquo;s officers, employers, employees, contractors, directors,
        related entities, trustees, affiliates, and successors from and against
        any and all liabilities and expense whatsoever &ndash; including without
        limitation, claims, damages, judgments, awards, settlements,
        investigations, costs, attorneys fees, and disbursements &ndash; which
        any of them may incur or become obligated to pay arising out of or
        resulting from the offering for sale, the sale, and/or use of the
        product(s), excluding, however, any such expenses and liabilities which
        may result from a breach of this Agreement or sole negligence or willful
        misconduct by Company, or any of its shareholders, trustees, affiliates
        or successors. Client shall defend Company in any legal actions,
        regulatory actions, or the like arising from or related to this
        Agreement. Client recognizes and agrees that all of the Company&rsquo;s
        shareholders, trustees, affiliates and successors shall not be held
        personally responsible or liable for any actions or representations of
        the Company. In consideration of and as part of my payment for the right
        to participate in Millionaire Millennial Programs, the undersigned,
        my heirs, executors, administrators, successors and assigns do hereby
        release, waive, acquit, discharge, indemnify, defend, hold harmless and
        forever discharge Millionaire Millennial and its subsidiaries,
        principals, directors, employees, agents, heirs, executors,
        administrators, successors, and assigns and any of the training
        instructors, guides, staff or students taking part in the training in
        any way as well as the venue where the Programs are being held (if
        applicable) and any of its owners, executives, agents, or staff
        (hereinafter &ldquo;Releasees&rdquo;) of and from all actions, causes of
        action, contracts, claims, suits, costs, demands and damages of whatever
        nature or kind in law or in equity arising from my participation in the
        Programs.
      </p>
      <p>&nbsp;</p>
      <p>RESOLUTION OF DISPUTES</p>
      <p>
        If not resolved first by good-faith negotiation between the parties,
        every controversy or dispute relating to this Agreement will be
        submitted to the American Arbitration Association. All claims against
        Company must be lodged within 100-days of the date of the first claim or
        otherwise be forfeited forever. The arbitration shall occur within
        ninety (90) days from the date of the initial arbitration demand. The
        parties shall cooperate to ensure that the arbitration process is
        completed within the ninety (90) day period. The parties shall cooperate
        in exchanging and expediting discovery as part of the arbitration
        process. The written decision of the arbitrators (which will provide for
        the payment of costs) will be absolutely binding and conclusive and not
        subject to judicial review and may be entered and enforced in any court
        of proper jurisdiction, either as a judgment of law or a decree in
        equity, as circumstances may indicate. In disputes involving unpaid
        balances on behalf of Client, Client is responsible for any and all
        arbitration and attorney fees.
      </p>
      <p>&nbsp;</p>
      <p>EQUITABLE RELIEF</p>
      <p>
        In the event that a dispute arises between the Parties for which
        monetary relief is inadequate and where a Party may suffer irreparable
        harm in the absence of an appropriate remedy, the injured Party may
        apply to any court of competent jurisdiction for equitable relief,
        including without limitation a temporary restraining order or
        injunction.
      </p>
      <p>&nbsp;</p>
      <p>NOTICES</p>
      <p>
        Any notices to be given hereunder by either Party to the other may be
        effected by personal delivery or by mail, registered or certified,
        postage prepaid with return receipt requested. Notices delivered
        personally shall be deemed communicated as of the date of actual
        receipt; mailed notices shall be deemed communicated as of three (3)
        days after the date of mailing. For purposes of this Agreement,
        &ldquo;personal delivery&rdquo; includes notice transmitted by fax or
        email. Email: support@millionairemillennial.me. This Agreement shall be
        binding upon and inure to the benefit of the parties hereto, their
        respective heirs, executors, administrators, successors and permitted
        assigns. Any breach or the failure to enforce any provision hereof shall
        not constitute a waiver of that or any other provision in any other
        circumstance. This Agreement constitutes and contains the entire
        agreement between the parties with respect to its subject matter,
        supersedes all previous discussions, negotiations, proposals, agreements
        and understandings between them relating to such subject matter. This
        Agreement shall be governed by and construed in accordance with the laws
        of the State of Nevada, United States of America.
      </p>
      <p>&nbsp;</p>
      <p>EARNINGS DISCLAIMER:</p>
      <p>
        Every effort has been made to accurately represent this product and its
        potential.
      </p>
      <p>
        This site and the products offered on this site are not associated,
        affiliated, endorsed, or sponsored by Facebook, nor have they been
        reviewed tested or certified by Facebook.
      </p>
      <p>
        There is no guarantee that you will earn any money using the techniques
        and ideas in these materials. Examples in these materials are not to be
        interpreted as a promise or guarantee of earnings. Earning potential is
        entirely dependent on the person using our product, ideas and
        techniques. We do not position this product as a &ldquo;get rich
        scheme.&rdquo;
      </p>
      <p>
        Any claims made of actual earnings or examples of actual results can be
        verified upon request. Your level of success in attaining the results
        claimed in our materials depends on the time you devote to the program,
        ideas and techniques mentioned, your finances, knowledge and various
        skills. Since these factors differ according to individuals, we cannot
        guarantee your success or income level. Nor are we responsible for any
        of your actions.
      </p>
      <p>
        Materials in our product and our website may contain information that
        includes or is based upon forward-looking statements within the meaning
        of the securities litigation reform act of 1995. Forward-looking
        statements give our expectations or forecasts of future events. You can
        identify these statements by the fact that they do not relate strictly
        to historical or current facts. They use words such as
        &ldquo;anticipate,&rdquo; &ldquo;estimate,&rdquo; &ldquo;expect,&rdquo;
        &ldquo;project,&rdquo; &ldquo;intend,&rdquo; &ldquo;plan,&rdquo;
        &ldquo;believe,&rdquo; and other words and terms of similar meaning in
        connection with a description of potential earnings or financial
        performance.
      </p>
      <p>
        Any and all forward looking statements here or on any of our sales
        material are intended to express our opinion of earnings potential. Many
        factors will be important in determining your actual results and no
        guarantees are made that you will achieve results similar to ours or
        anybody else&rsquo;s, in fact no guarantees are made that you will
        achieve any results from our ideas and techniques in our material.
      </p>
      <p>&nbsp;</p>
      <p>OUR MINIMUM GUARANTEES</p>
      <p>
        Unless otherwise noted, all products come with a 30 days action based
        guarantee. Longer conditional guarantees may apply, so check the sales
        material at the time of your order for details. If you do not understand
        or agree with any of these conditions, please do not order this
        material. If you require further clarification, please contact
        support@millionairemillennial.me
      </p>
      <p>&nbsp;</p>
      <p>&copy; Millionaire Millennial</p>
      <p>&nbsp;</p>
      <p id="earnings">
        Earnings Disclaimer: The information presented in this Website is
        intended to be for your educational and entertainment purposes only. We
        are not presenting you with a business opportunity. We are not
        presenting you with a distributorship. We are not making any claims as
        to income you may earn. We are not presenting you with an opportunity to
        get rich. Before embarking on any endeavor, please use caution and seek
        the advice your own personal professional advisors, such as your
        attorney and your accountant. Where income figures are mentioned (if
        any), those income figures are anecdotal information passed on to us
        concerning the results achieved by the individual sharing the
        information. We have performed no independent verification of the
        statements made by those individuals. Please do not assume that you will
        make those same income figures. Please do not construe any statement in
        this website as a claim or representation of average earnings. There are
        NO average earnings. Testimonials and statements of individuals are not
        to be construed as claims or representations of average earnings. We
        cannot, do not, and will not make any claims as to earnings, average, or
        otherwise. Success in any endeavor is based on many factors individual
        to you. We do not know your educational background, your skills, your
        prior experience, or the time you can and will devote to the endeavor.
        Please perform your own due diligence before embarking on any course of
        action. Follow the advice of your personal qualified advisors. There are
        risks in any endeavor that are not suitable for everyone. If you use
        capital, only &ldquo;risk&rdquo; capital should be used. There is no
        guarantee that you will earn any money using any of the ideas presented
        in our in materials. Examples in our materials are not to be interpreted
        as a promise or guarantee of earnings. Many factors will be important in
        determining your actual results and no guarantees are made that you will
        achieve results similar to ours or anybody else&rsquo;s. No guarantee is
        made that you will achieve any result at all from the ideas in our
        material. You agree that we will not share in your success, nor will we
        be responsible for your failure or for your actions in any endeavor you
        may undertake. Please understand that past performance cannot be an
        indication of possible future results. Materials in our product and our
        website may contain information that includes or is based upon
        forward-looking statements within the meaning of the securities
        litigation reform act of 1995. Forward-looking statements give our
        expectations or forecasts of future events. You can identify these
        statements by the fact that they do not relate strictly to historical or
        current facts. They use words such as &ldquo;anticipate,&rdquo;
        &ldquo;estimate,&rdquo; &ldquo;expect,&rdquo; &ldquo;project,&rdquo;
        &ldquo;intend,&rdquo; &ldquo;plan,&rdquo; &ldquo;believe,&rdquo; and
        other words and terms of similar meaning in connection with a
        description of potential earnings or financial performance. Any and all
        forward looking statements in our materials are intended to express our
        opinion of earnings potential. They are opinions only and should not be
        relied upon as fact.
      </p>
    </Layout>
  )
}
